.modal-body {
	max-height: 600px;
	overflow-y: scroll !important;
}
.header-table {
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content:space-between;
}

.title-table {
	font-weight: bolder;
	font-family: Verdana, Geneva, Tahoma, sans-serif ;
	font-size: 40px ;
}

.p-table {
	font-weight: bolder;
	font-family: 'Times New Roman', Times, serif;
	font-size: 25px;
}

.actions-pedido {
	display: flex;
	width: 100%;
	justify-content: end;
	flex-direction: row;
}

.line {
	border-color: darkblue;
}

.p-form {
	font-family: 'Times New Roman', Times, serif;
	font-size: 20px;
	border-radius: 30px;
}

.modal-header {
	font-family: 'Times New Roman', Times, serif;
	padding: 0;
}

.title-span{
	color: black;
	font-weight: bold;
}

.info-form {
	margin-bottom: 10px;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.container {
	padding-top: 10px !important;
}

.acoes {
    padding: 20px;
}

.acoes:hover {
    color:gray;
    cursor: pointer;
}

@media (max-width: 960px) {
	.btn-email{
		margin-bottom: 20px !important;
	}
}

.link-anexo{
	color:black;
}

.link-anexo:hover {
	color:gray;
	cursor: pointer;
}

.btn-manage:hover {
	cursor: pointer;
}

.select__input-container {
	color: black;
}