.form-control:focus {
	color: black ;
}

.header-table {
	text-align: center;
}

.title-table {
	font-weight: bolder;
	font-family: 'Times New Roman', Times, serif;
	font-size: 30px;
}

.p-table {
	font-weight: bolder;
	font-family: 'Times New Roman', Times, serif;
	font-size: 20px;
}

.line {
	border-color: darkblue;
}

.p-form {
	font-family: 'Times New Roman', Times, serif;
	font-size: 20px;
	border-radius: 30px;
}

.md-form {
	color:brown;
}

.modal-header {
	font-family: 'Times New Roman', Times, serif;
	padding: 0;
}

.title-span{
	color: black;
	font-weight: bold;
}

.info-form {
	margin-bottom: 10px;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}