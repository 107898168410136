.header-table {
	text-align: center;
	align-items: center;
}

.card-body {
	text-align: center;
}

.icon {
	color: white;
	width: 100px;
}

.item-prod {
	text-align: left;
	font-weight: bolder;
}