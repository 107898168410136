.wrapper {
	position: relative;
	top: 0;
	height: 100vh;
}

.content {
	margin-top: -70px;
	margin-left: 75px;
	margin-right: -200px;
	padding: 30px 15px;
	min-height: "calc(100vh - 123px)";
}

.container {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

@media (max-width: 960px) {
	.content {
		margin-top: -120px;
		margin-left: -17px;
		margin-right: -270px;
		padding: 30px 15px;
		min-height: "calc(100vh - 123px)";
	}
	
	.container {
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
	}
}