.title-page{
font-family: 'Times New Roman', Times, serif;
color: black;
font-size: 25px;
}

.MuiToolbar-root {
	margin-top: 40px;
}

.navigation {
	width: 100%;
	padding-top: 1px;
}

.logout {
	color: black;
	font-size: .8em;
	font-family: 'Oswald', sans-serif;
	position: relative;
	right: -18px;
	bottom: -4px;
	overflow: hidden;
	letter-spacing: 3px;
	opacity: 0;	
	transition: opacity .45s;
-webkit-transition: opacity .35s;
}

.button-user {
	text-decoration: none;
	float: right;
	min-width: 150px;
	height: 20px;
	transition: width .35s;
	-webkit-transition: width .35s;
	overflow: hidden
}