.form-control:focus {
	color: black ;
}

.header-table {
	text-align: center;
}

.icon-add {
	display: inline-block;
    padding-top: 30px;
	padding-left: 5px;
}

.title-table {
	font-weight: bolder;
	font-family: 'Times New Roman', Times, serif;
	font-size: 30px;
}

.p-table {
	font-weight: bolder;
	font-family: 'Times New Roman', Times, serif;
	font-size: 20px;
}

.actions-produto {
	display: flex;
	width: 100%;
	justify-content: end;
	flex-direction: row;
}

.line {
	border-color: darkblue;
}

.p-form {
	font-family: 'Times New Roman', Times, serif;
	font-size: 20px;
	border-radius: 30px;
}

.md-form {
	color:brown;
}

.modal-header {
	font-family: 'Times New Roman', Times, serif;
	padding: 0;
}

.title-span{
	color: black;
	font-weight: bold;
}

.icon{
  padding-left: 5px;
  color:aqua !important; 
	cursor: pointer;
}

.info-form {
	margin-bottom: 10px;
	padding: 0 10px 0 10px;
	border-radius: 5px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
}

.btn {
  margin-right: 40px !important;
  color: white !important;
  background-color: green !important;
  padding: 8px 20px !important;
  border-radius: 8px !important;
  font-size: 12px;
  font-weight: bold;
}