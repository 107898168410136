.acoes {
    display: inline-block;
    padding: 8px;
}

.acoes:hover {
    color:gray;
    cursor: pointer;
}
.logo-a{
    width: 100px;
    height: 75px;
}
@media print {
    .pdf-container {
      display: none;
    }
  }