.MuiPaper-root {
  background-color: transparent !important;
  color: white !important;
}

.MuiTypography-colorPrimary {
  color: white !important;
  font-weight: bold !important;
  /* margin-left: 0%; */
}

/* .MuiButton-contained.Mui-disabled {
  color: white !important;
  box-shadow: whitesmoke !important;
  background-color: #1d1d2a;
} */

.errspan {
  float: right;
  margin-right: 6px;
  margin-top: -50px;
  position: relative;
  z-index: 2;
}

.svg-inline--fa {
  font-size: 20px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
-webkit-text-fill-color: black !important;
}

label.active {
  color: black;
}

label.focus {
  color: whiblackte;
}

input:focus + label {
  color: black 
}

.form-control {
  color: black;
}

.form-control:focus {
  color: black ;
}

.md-form label {
  color: black;
}

.container {
  padding-top: 50px;
}

.div-fundo {
  height: 100vh;
  width: 100vw;
  background: url(../../assets/img/background.jpg);
  /* background-repeat: no-repeat; */
  background-size: cover;
  background-position: center; 
}

.image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 

}

.paper {
  padding-top: 40px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar {
  margin-top: 20px;
  background-color: white,
}

.submit {
  margin-bottom: 100px;
}

.card {
  margin-top: 10%; 
  margin-left: 350px;
  margin-right: 350px;
}


@media (max-width: 960px) {
  .card {
    margin-top: 35% !important; 
    margin-bottom: 30% !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
}

@media (max-height: 500px) {
  .div-fundo {
    height: 100%;
  } 
}