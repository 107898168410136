.acoes {
    display: inline-block;
    padding: 5px;
}

.acoes:hover {
    color:gray;
    cursor: pointer;
}

.logo-a{
    width: 100px;
    height: 75px;
}