* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  min-width: 100vw;
  overflow-x: hidden;
  position: relative;
}